import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--subscriber-details"
export default class extends Controller {
  connect() {
    $('#subscriber_edit_form').validate()
    subscriber_validation();
  }

  modalApplicantDetailedit(event){
   let element = event.currentTarget
   let $form = $('#product-info-igw');

    if(element.checked){
      $('.modal_subscriber_name').val($form.data('fullName'));
      $('.modal_subscriber_address').val($form.data('address'));
      $('.modal_subscriber_city').val($form.data('city'));
      $('.modal_subscriber_zip').val($form.data('zip'));
      $('.modal_subscriber_state').val($form.data('state'));
    }else{
      $('.modal_subscriber_name').val('');
      $('.modal_subscriber_address').val('');
      $('.modal_subscriber_city').val('');
      $('.modal_subscriber_zip').val('');
      $('.modal_subscriber_state').val('');
    } 
  }
}

window.subscriber_validation = function(){
  console.log('subscriber_validation')
  $('#more_detail_subscriber_name').rules('add', {
    required: true,
    alphabetswithspace: true,
    messages: {
      required: 'Please enter subscriber name.',
      alphabetswithspace: 'Please enter only alphabates.'
    }
  });
  
  $('#more_detail_subscriber_address').rules('add', {
    required: true,
    messages: {
      required: 'Please enter subscriber address.'
    }
  });

  $.validator.addMethod("alphabetswithspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
  });

}
