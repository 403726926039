import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="member--profile"
export default class extends Controller {
  connect() {
    console.log('member_profile_edit_from connect')
    StoreProfileValidation()
  }

  merchantToggel(){
    let element = event.currentTarget
    const details = element.nextElementSibling;
    const arrow = element.querySelector('.arrow');
    
    if (details.style.display === "block") {
      details.style.display = "none";
      arrow.innerHTML = "&#x25B2;";
    } else {
      details.style.display = "block";
      arrow.innerHTML = "&#x25BC;";
    }
  }
}

window.StoreProfileValidation = function() {
  console.log('StoreProfileValidation')
  $("#member_profile_edit_from").validate({
    rules: {
      "store[display_member_name]": {
        required: true,
        minlength: 2,
        maxlength: 100,
        blankSpace: true
      },
      "store[email]": {
        required: true,
        emailregex: true
      },
      "store[phone_number]": {
        required: true,
        storephoneregex: true,
        minlength: 12,
        maxlength: 12
      }
    },
    messages: {
      "store[display_member_name]": {
        required: "The name is required.",
        minlength: "The name must be more than 2 and less than 100 characters long.",
        maxlength: "The name must be more than 2 and less than 100 characters long.",
        blankSpace: 'Blank space not allowed.'
      },
      "store[email]": {
        required: "The enter email address.",
        emailregex: "Please enter valid email address."
      },
      "store[phone_number]": {
        required: 'Please enter phone number.',
        storephoneregex: "Phone number must be 10 numeric digits only.",
        minlength: "The phone no.should be 10 digits.",
        maxlength: "The phone no.should be 10 digits."
      }
    }
  });

  $.validator.addMethod("blankSpace", function(value, element) {
   if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });

  $.validator.addMethod("emailregex", function(value, element) {
    return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
  });
  
  $.validator.addMethod("storephoneregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  });
 
  $('#store_phone_number').keyup(function(){
    addDashes($(this))
  })
}

