import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--igw-product-detail"
export default class extends Controller {
  connect() {
    var amount = $('#applicant_product_amount').val();
    if(amount.length > 0){
      $('#applicant_product_amount').trigger('keyup');
    }
    $('#product_detail_form input').keyup(function(){
      totalAmountIgw()
      validation_checker()
      downPaymentMinMax()
      loanAmountCal()
    });

    $('#applicant_product_amount').on('keyup change',function(){
      var target_down_payment = $('#product_detail_form').data('target-down-payment');
      var minimum_down_payment = $('#product_detail_form').data('minimum-down-payment');
      var maximum_down_payment = $('#applicant_product_amount').val()
      if(maximum_down_payment.length != 0){
        var maximum_down_payment = parseFloat(maximum_down_payment).toFixed(2) - 0.1
        var targetProductAmount = $('#applicant_product_amount').val()*target_down_payment/100
        var productDownPayment = $('#applicant_product_amount').val()*minimum_down_payment/100
        $('#applicant_deposit').val(targetProductAmount.toFixed(2));
        $('#applicant_deposit').keyup();
      }
    });

    $('#applicant_deposit').on('keyup change',function(){
      var maximum_down_payment = $('#applicant_product_amount').val()
      var maximum_down_payment = parseFloat(maximum_down_payment).toFixed(2) - 0.1
      var minimum_down_payment = $('#product_detail_form').data('minimum-down-payment');
      var productDownPayment = $('#applicant_product_amount').val()*minimum_down_payment/100
      $('#applicant_deposit').attr('max', parseFloat(maximum_down_payment.toFixed(2)))
      $('#applicant_deposit').attr('min', parseFloat(productDownPayment.toFixed(2)))

      $('#applicant_deposit').rules('add', {
        max: parseFloat(maximum_down_payment.toFixed(2)),
        min: parseFloat(productDownPayment.toFixed(2)),
        messages: {
          max: 'Sorry, Down Payment entered must be less purchase price.',
          min: 'Sorry, Down Payment entered must be greater than or equal to ' + productDownPayment.toFixed(2) + '.'
        }
      });
    })

    // $('#custom_term').on('keyup change', function(){
    //   var target_spi_tier = $('#product_detail_form').data('target-spi-tier');
    //   var apr = {}
    //   if($("#custom_term").val() <= 12){
    //     apr = { 'A' : 12.5, 'B' : 17.5, 'C' : 22.5, 'D' : 22.5 }}
    //   else if(($("#custom_term").val() > 12) && ($("#custom_term").val() <= 18)){
    //     apr = { 'A' : 15.0, 'B' : 20.0, 'C' : 25.0, 'D' : 25.0 }}
    //   else if(($("#custom_term").val() > 18) && ($("#custom_term").val() <= 24)){
    //     apr = { 'A' : 17.5, 'B' : 22.5, 'C' : 27.5, 'D' : 27.5 }}
    //   else{
    //     apr = { 'A' : 17.5, 'B' : 22.5, 'C' : 27.5, 'D' : 27.5 }}
    //   apr = apr[target_spi_tier]
    //   $('.apr').text(apr.toFixed(2) + '%')
    // })
  }

  copyApplicantDetail(event){
    let element = event.currentTarget;
    let $form = $('#product_detail_form');
    if (element.checked) {
      $('#more_detail_subscriber_name').val($form.data('fullName'));
      $('#more_detail_subscriber_address').val($form.data('address'));
      $('#more_detail_subscriber_city').val($form.data('city'));
      $('#more_detail_subscriber_zip').val($form.data('zip'));
      $('#more_detail_subscriber_state_id').val($form.data('state'));
    } else {
      $('#more_detail_subscriber_name').val('');
      $('#more_detail_subscriber_address').val('');
      $('#more_detail_subscriber_city').val('');
      $('#more_detail_subscriber_zip').val('');
      $('#more_detail_subscriber_state_id').val('');
    }
  }
}

window.emiCalculator = function(apr, loan_amount, terms){
  if(apr > 0){
    apr = (apr/1200)
    var x = (1 + apr) ** terms
    return (loan_amount * (apr * x) / (x - 1));
  }else{
    return (loan_amount / terms);
  }
}

window.loanAmountCal = function(){
  let deposit_amount = 0
  let total_amount   = 0
  let service_fee = 79
  let shipping_fee = 0
  if($("#applicant_deposit").val().length > 0)
    deposit_amount = $("#applicant_deposit").val();
  if($("#applicant_sale_total_amount").val().length > 0)
    total_amount = $("#applicant_sale_total_amount").val();
  if($("#applicant_service_fee").val().length > 0)
    service_fee = $("#applicant_service_fee").val();
  if($("#applicant_shipping_fee").val().length > 0)
    shipping_fee = $("#applicant_shipping_fee").val();
  let loanAmount = parseFloat(total_amount) - parseFloat(deposit_amount) - parseFloat(service_fee) - parseFloat(shipping_fee)
  if(loanAmount < 0){
    loanAmount = 0
  }

  let term;

  let igw_term = parseInt($('#custom_term').val())
  let initial_payment =  parseFloat(deposit_amount) + parseFloat(service_fee) + parseFloat(shipping_fee)

  if(String(igw_term) != 'NaN' && igw_term > 0){
    term = igw_term
  }else{
    term = 0
  }

  let apr        = parseFloat($('#igw_apr').val())
  let paymentAmount = emiCalculator(apr, loanAmount, term)
  if(String(paymentAmount) == 'NaN' || String(paymentAmount) == 'Infinity'){
    paymentAmount = 0.0
  }
  $('.termSection').text(term);
  $('.paymentAmountSection').val(paymentAmount.toFixed(2));
  $('.loanAmountSection').text(loanAmount.toFixed(2));
  $('.InitialPaymentAmountSection').text(initial_payment.toFixed(2))
}

window.downPaymentMinMax = function(){
  let amount = $('#applicant_sale_total_amount').val();
  let min = 0
  let max = 7000
  if(amount > 0){
    // min = parseFloat(amount)*0.1
    // max = parseFloat(amount)*0.25
    max = parseFloat(amount)
  }
  $('#applicant_deposit').attr('max', max.toFixed(2))
  $('#applicant_deposit').attr('min', min.toFixed(2))
  return { "max": max.toFixed(2), "min": min.toFixed(2) }
}

// window.updateApr = function(){
//   var target_spi_tier = $('#product-info-igw').data('target-spi-tier');
//   var apr = {}
//   if($("#applicant_igw_term").val() <= 12){
//     apr = { 'A' : 12.5, 'B' : 17.5, 'C' : 22.5, 'D' : 22.5 }}
//   else if(($("#applicant_igw_term").val() > 12) && ($("#applicant_igw_term").val() <= 18)){
//     apr = { 'A' : 15.0, 'B' : 20.0, 'C' : 25.0, 'D' : 25.0 }}
//   else if(($("#applicant_igw_term").val() > 18) && ($("#applicant_igw_term").val() <= 24)){
//     apr = { 'A' : 17.5, 'B' : 22.5, 'C' : 27.5, 'D' : 27.5 }}
//   else{
//     apr = { 'A' : 17.5, 'B' : 22.5, 'C' : 27.5, 'D' : 27.5 }}
//   apr = apr[target_spi_tier]
//   $('.apr').text(apr.toFixed(2) + '%')
// }

window.totalAmountIgw = function(){
  console.log('controller call')
  let service_fee = 79
  let sale_tax    = 0
  let amount      = 0
  let total       = 0
  let shipping_fee = 0
  let new_total = 0

  if($("#applicant_service_fee").val().length > 0)
    service_fee = $("#applicant_service_fee").val();
  if($("#applicant_shipping_fee").val().length > 0)
    shipping_fee = $("#applicant_shipping_fee").val();
  if($("#applicant_sale_tax").val().length > 0)
    sale_tax    = $("#applicant_sale_tax").val();
  if($("#applicant_product_amount").val().length > 0)
    amount      = $("#applicant_product_amount").val();
  total       =  parseFloat(amount) + parseFloat(sale_tax) + parseFloat(service_fee) + parseFloat(shipping_fee)
  new_total       =  parseFloat(amount) + parseFloat(sale_tax)
  
  $('#applicant_sale_tax').rules('add', {
    required: true,
    // max: 50000,
    messages: {
      required: 'Please enter sales tax.',
      // max: 'Sorry, sales tax must be between 0 and 50000.'
    }
  });

  //$('#applicant_product_amount').attr('max', product_amount_max.toFixed(2))
  $('#applicant_product_amount').attr('title', 'Purchase price cannot be less than 1.')
  
  $("#applicant_sale_total_amount").val(total.toFixed(2));
  console.log(total)
  $("#applicant_sale_total_amount_new").val(new_total.toFixed(2));
}


window.validation_checker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs;
  documentUploadValidation();
  if($('#applicant_info').length == 1){
    myInputs = $('#applicant_info input:required, #applicant_info select:required, #applicant_info textarea:required').filter( ":visible" );
  }else{
    myInputs = $('input:required, select:required, textarea:required');
  }

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length

  if ( $('#product-info').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  if (inputsWithValues == totalInputs) {
    $(".submission").prop("disabled", false);
  } else {
    $(".submission").prop("disabled", true);
  }
}

