import jQuery from "jquery";
window.$ = jQuery
window.jQuery = jQuery

window.submitBtnEnableDisable = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('input:required, select:required');
  myInputs.each(function(e) {
    if ($(this).val() != '' && $(this).valid()) {
      inputsWithValues += 1;
    }
  });

  totalInputs = myInputs.length
  if($('#consent-check-box').length == 1){
    totalInputs += 1
    inputsWithValues += $('#consent-check-box:checked').length == 1 ? 1 : 0
  }

  if ( $('#product_details').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  if (inputsWithValues == totalInputs) {
    $(".btn-continue").prop("disabled", false);
  } else {
    $(".btn-continue").prop("disabled", true);
  }
}

window.addDashes = (element) => {
  var key_code = event.keyCode
  if(key_code == 8 || key_code == 37 || key_code == 39) //backspace, left, right key event reject
    return false;
  var r = /(\D+)/g,
  npa = '',
  nxx = '',
  last4 = '';
  element.val(element.val().replace(r, ''));
  npa = element.val().substr(0, 3);
  if(element[0]['id'] == 'applicant_ssn' || element[0]['id'] == 'applicant_co_ssn'){
    nxx = element.val().substr(3, 2);
    last4 = element.val().substr(5, 4);
  }else{
    nxx = element.val().substr(3, 3);
    last4 = element.val().substr(6, 4);
  }
  var dash_number = npa + '-' + nxx + '-' + last4
  if(dash_number != '--')
    element.val(npa + '-' + nxx + '-' + last4);
}

window.addDashesCard = (element) => {
  console.log('addDashes')
  var key_code = event.keyCode
  if(key_code == 8 || key_code == 37 || key_code == 39) //backspace, left, right key event reject
    return false;
  var r = /(\D+)/g,
  npa = '',
  nxx = '',
  nxxx = '',
  last4 = '';
  element.val(element.val().replace(r, ''));
  npa = element.val().substr(0, 4);
  nxx = element.val().substr(4, 4)
  nxxx = element.val().substr(8, 4)
  last4 = element.val().substr(12, 4)

  var dash_number = npa + '-' + nxx + '-' + nxxx + '-' +  last4
  if(dash_number != '--')
    element.val(npa + '-' + nxx + '-' + nxxx + '-' + last4);
}

window.addSlash = (element) => {
  var key_code = event.keyCode
  if(key_code == 8 || key_code == 37 || key_code == 39) //backspace, left, right key event reject
    return false;
  var r = /(\D+)/g,
  npa = '',
  nxx = '',
  last4 = '';
  element.val(element.val().replace(r, ''));
  npa = element.val().substr(0, 2);
  nxx = element.val().substr(2, 2);
  last4 = element.val().substr(4, 4);
  var dash_number = npa + '/' + nxx + '/' + last4
  if(dash_number != '//')
    element.val(npa + '/' + nxx + '/' + last4);
}

window.maxLimit = function(element){
  let amount = $(element).val()
  if(amount > 100000000){
   var amount_str = (amount).toLocaleString('fullwide', {useGrouping:false})
    $(element).val(amount_str.slice(0,9))
  }
}

window.twoDecimalPoint = function(element){
  $(element).val(parseFloat($(element).val()).toFixed(2));
}

window.CustomTerm = function(element){
  $(element).val((parseInt($(element).val())));
}


window.calculate = function(element){
  var amount = 0
  var qty = 0
  var total_qty = 0
  var price = 0

  $('.quantity').each(function(index, element){
    if(parseFloat(element.value) != '' && $(element).is(":visible")){
      total_qty += parseFloat(element.value)
    }
  });
  qty   = element.parent().parent().children().children('.quantity').val()
  price = element.parent().parent().children().children('.unit_price').val()

  if( qty != ''){
    qty = parseInt(qty)
  }
  if( price != ''){
    price = parseFloat(price)
  }
  element.parent().parent().children().children('.amount').text((price*qty).toFixed(2))
  element.parent().parent().children().children('.amount').next().val((price*qty).toFixed(2))
  $('.total-order').text(total_qty)
  total_calculate()
  $('#applicant_sale_total_amount').focusout();
  $('#product-info').mouseover();
}


window.total_calculate = function (){
  var total_amount = 0.0, sale_tax = 0.0;
  $('.amount').each(function(index, element){
    if(element.textContent != '' && $(element).is(":visible") ){
      total_amount += parseFloat(element.textContent)
    }
  });
  sale_tax = $('#applicant_sale_tax').val()
  if( sale_tax != ''){
    total_amount += parseFloat(sale_tax)
  }
  
  // $('.total-amount').text(total_amount.toFixed(2))
  if($('#product_details_form').length == 0){
    if(document.getElementById('updated_limit') != null){
     var creditLimit = document.getElementById('updated_limit').getAttribute('data-credit-limit')
     var sale_total_amount = $('#product-info').data('sale-total-amount')
     console.log((parseFloat(creditLimit) + parseFloat(sale_total_amount)))
      if(creditLimit.length > 0){
        $('#applicant_sale_total_amount').attr('max', (parseFloat(creditLimit) + parseFloat(sale_total_amount)))
        $('#applicant_sale_total_amount').attr('title', `Total amount must be between 1000 and ${(parseFloat(creditLimit) + parseFloat(sale_total_amount))}`)
      }
    }
    $('#applicant_sale_total_amount').val(total_amount.toFixed(2))
  }
  $('#product_details').mouseover();
}

window.nextPaymentDateLimit = function(){
  let paymentFrequency = $('#applicant_payment_frequency').val()
  $('#applicant_next_payment_date').val('')
  switch(paymentFrequency) {
   case 'weekly': {
      // must be within the next 7 days from today
      $('#applicant_next_payment_date').datepicker('setStartDate', '+1D')
      $('#applicant_next_payment_date').datepicker('setEndDate', '+7D');
      break;
    }
    case 'every_2_weeks': {
      // must be within the next 14 days from today
      $('#applicant_next_payment_date').datepicker('setStartDate', '+1D')
      $('#applicant_next_payment_date').datepicker('setEndDate', '+14D');
      break;
    }
    case 'monthly': {
      // must be within the next 31 days from today
      $('#applicant_next_payment_date').datepicker('setStartDate', '+1D')
      $('#applicant_next_payment_date').datepicker('setEndDate', '+31D');
      break;
    }
    default: {
      break;
    }
  }
}

window.setEndDateNextPaymentDate = function(endDay){
  if($('#applicant_next_payment_date').length == 1){
    $('#applicant_next_payment_date').datepicker('setStartDate', '+1D');
    $('#applicant_next_payment_date').datepicker('setEndDate', endDay);
  }
}

window.numberFormatter = (element) => {
  var key_code = event.keyCode
  if(key_code == 8 || (event.which >= 37 && event.which <= 40)) //backspace, left, right key event reject
    return false;
   $(element).val(function(index, value) {
    return removeZeroFromStart(value)
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    ;
  });
}

window.numberFormatterWithDecimal = (element) => {
  var key_code = event.keyCode
  if(key_code == 8 || (event.which >= 37 && event.which <= 40)) //backspace, left, right key event reject
    return false;
   $(element).val(function(index, value) {
    var part_first  = value.split('.')
    var part_second = ''

    part_first = removeZeroFromStart(part_first[0])
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    ;

    part_second = value.split('.')[1]

    if(part_second == undefined){
      return part_first
    }else{
      part_second = (part_second.length > 2 ? parseFloat(value.replace(/,/g , '')).toFixed(2).split('.')[1] : part_second)
      return part_first + '.' + part_second
    }
  });
}

window.removeZeroFromStart = function(value){
  return String(parseInt(value.replace(/,/g , '')))
}

window.removedNotRequiredFieldValidation = function(notRequiredFieldArray){
  // renoved Jquery validation unrequired according Lender.
  $.each( notRequiredFieldArray, function(index, field) {
    $('#applicant_' + field).rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });

    // removed required property from HTML tags
    $('#applicant_' + field).prop('required', false)
  })
}

window.applyDatePicker = function() {
  $(".birthDate").datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    startDate: '01/01/1900',
    endDate: '-18Y'
  });
}

