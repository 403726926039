import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-payment-statuses-report"
export default class extends Controller {
  connect() {
    $('#select_all').change(function() {
      $('td.select-item input').prop('checked', this.checked)
      if(this.checked){
        $('td.select-item').parent().addClass('selected-row')
      }else{
        $('td.select-item').parent().removeClass('selected-row')
      }
      $(this).attr('data-selected-ids', selectedAppIds().join(','))
      $('.batch-action-section button').text($('.batch-action-section button').data('text') + ` (${selectedAppIds().length}) `)
    })

    $('#loading_data').hide()
    if($('#payment_status_table_section').length == 1){
      $('#payment_status_table_section').DataTable({
        paging: false,        // Disable pagination
        ordering: false,      // Disable sorting
        info: false,          // Disable table information display
        searching: true       // Enable search functionality
      });
      $('#payment_status_table_section_filter').detach().prependTo('#searchContainer');
      $('#payment_status_table_section_filter label').css('display', 'inline-flex');
      $('#payment_status_table_section_filter input').css('margin-left', '10px');
    }
    
  }

  highlight(e) {
    let element = e.target
    if(element.checked){
      $(element).parent().parent().addClass('selected-row')
    }else{
      $(element).parent().parent().removeClass('selected-row')
    }

    $('#select_all').attr('data-selected', selectedAppIds().join(','))
    $('.batch-action-section button').text($('.batch-action-section button').data('text') + ` (${selectedAppIds().length}) `)
  }

  batchActionOpen(){
    $('#batch_applicant_ids').val(selectedAppIds().join(','))
    $('#batchActionModal .modal-body p b').text(selectedAppIds().length)
  }

  batchActionUpdate(e){
    $('.modal-backdrop').remove()
  }


}

window.selectedAppIds = function(){
  let data = []
  $('td.select-item input:checked').each(function(_index, item) {
    data.push(item.value)
  })

  return data
}
