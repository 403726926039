import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-recollection"
export default class extends Controller {
  connect() {
    $('.modal-backdrop').remove()
    $('.modal').remove()

    // validator()
    BankDetailvalidationChecker()
    CardDetailvalidationChecker()

    $('#payment_recollection_authentication_password').keyup(function(){
      $('#incorrect > p').html("");
    })

    $('.bank_detail_section').mouseover(function(){
      BankDetailvalidationChecker()
    })

    $('#recollect_card_detail_edit_form input').mouseover(function(){
      CardDetailvalidationChecker()
    });

    $('#recollect_card_detail_edit_form input').keyup(function(){
      CardDetailvalidationChecker()
    });

    $('#card_info_card_number').keyup(function(){
      addDashesCard($(this))
    })

    $('#applicant_phone').keyup(function(){
      addDashes($(this))
    })

    $("#card_info_card_expiry").datepicker( {
      forceParse: false,
      format: "mm/yyyy",
      startDate: new Date(),
      minViewMode: "months",
      maxViewMode: 'years',
      autoclose: true
    });
    
    $('#card_info_card_expiry').focusout(function () {
      var dateInput = $(this).val();
      var regex = /^(0[1-9]|1[0-2])\/\d{4}$/; // Regular expression for MM/YYYY format
      if (!regex.test(dateInput)) {
        $(this).val(''); // Clear the input field if format is incorrect
      } else {
      }
    })

    $('#recollect_card_detail_edit_form').validate({
      rules: {
        'card_info[card_number]': {
          required: true,
          cardNumberRegex: true,
          minlength: 19,
          maxlength: 19
        },
        'card_info[cvv]': {
          required: true,
          digits: true,
          minlength: 3,
          maxlength: 3
        }
      },
      messages: {
        'card_info[card_number]': {
          required: "Please enter debit card number.",
          cardNumberRegex: "Please enter a valid debit card number.",
          minlength: 'Sorry, debit card must be 16 digits.',
          maxlength: 'Sorry, debit card must be 16 digits.'
        },
        'card_info[cvv]': {
          required: 'Please enter vaild CVV number.',
          digits: 'Sorry, only numbers are allowed.',
          maxlength: "Sorry, CVV number must be 3 digits.",
          minlength: "Sorry, CVV number must be 3 digits."
        }
      }

    });

    $('#recollect_bank_detail_edit_form').validate({
      rules: {
        'applicant[routing_number]': {
          minlength: 9,
          maxlength: 9,
          required: true,
          digits: true,
          allZero: true
        },
        'applicant[account_number]': {
          required: true,
          digits: true,
          minlength: 6,
          maxlength: 25,
          allZero: true
        },
        'applicant[bank_name]':{
          maxlength: 256,
          required: true,
          blankSpace: true,
          alphabetWithSplSym: true
        }
      },
      messages: {
        'applicant[routing_number]': {
          minlength: 'Sorry, routing number must be 9 digits',
          maxlength: 'Sorry, routing number must be 9 digits',
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'

        },
        'applicant[account_number]': {
          maxlength: "Sorry, account number must be between 6 and 25 digits.",
          minlength: "Sorry, account number must be between 6 and 25 digits.",
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.'
        },
        'applicant[bank_name]':{
          required: 'Please enter bank name.',
          maxlength: "Sorry, bank name must be less then 256 words.",
          blankSpace: 'Blank space not allowed.',
          alphabetWithSplSym: 'Please enter valid bank name.'
        }
      }
    });

    $.validator.addMethod("blankSpace", function(value, element) {
      if(value.replaceAll(' ', '').length == 0){
        return false
      }else{
        return this.optional(element) || true
      }
    });

    $.validator.addMethod("allZero", function(value, element) {
      return this.optional(element) || !(! + value);
    });

    $.validator.addMethod("alphabetWithSplSym", function(value, element) {
      return this.optional(element) || /^(?=.*[A-Za-z\d]{2,})[A-Za-z\d!@#$%^&*()_+~=`~-]{2,}/.test(value);
    });

    $.validator.addMethod("cardNumberRegex", function(value, element) {
      return this.optional(element) || /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(value);
    });

  }
}

window.BankDetailvalidationChecker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('#recollect_bank_detail_edit_form input:required');

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  if (inputsWithValues == myInputs.length) {
    $(".applicant_submission").prop("disabled", false);
  } else {
    $(".applicant_submission").prop("disabled", true);
  }
}


window.CardDetailvalidationChecker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs         = $('#recollect_card_detail_edit_form input:required');

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  if (inputsWithValues == myInputs.length) {
    $(".applicant_submission").prop("disabled", false);
  } else {
    $(".applicant_submission").prop("disabled", true);
  }
}
