import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spi-entry-module"
export default class extends Controller {
  connect() {

    // spi yearly report
    $('.spi_yearly_report_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
    });
    // =======================
    $('#loading_data').hide()
    productRulesSet()
    pymtNotesValidation()
    DueAmountCalculate()
    $('.edit_first_payment_date').css('cursor', 'pointer')
    $('#applicant_account_status').select2();
    $('#applicant_payment_plan_frequency').select2();
    $('.admin_member_id').select2({
      minimumInputLength: 3
    });

    $('#applicant_id').select2({
    })

    $('#applicant_next_first_due_date').change(function(){
      if($(this).val() != ''){
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });

    // $('#applicant_id').change(function () {
    //   var applicant_id = $(this).find('option:selected').text().split('-')[0]
    //   if ($('#applicant_id').data('triggerAction') != false){
    //     $.ajax({
    //       type: "get",
    //         url: `/admin/spi_entry_modules/${applicant_id}/get_store_name`,
    //         data: {},
    //         contentType: "application/json",
    //         dataType: "json",
    //       success: function(data) {
    //         $('#member_id').attr('triggerAction', false)
    //         $('#member_id').val(data['id']).trigger('change.select2');
    //       }
    //     });
    //   }else{
    //     $('#applicant_id').attr('triggerAction', true)
    //   }
    // })

    $('#pymt_plan_txn_form').validate()
    $(".paidDate").each(function(index, item) {
      $(item).datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        endDate: new Date(),
        startDate: new Date($(item).data('end-date'))
      });
    })

    $('#applicant_pba_date').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date($('#applicant_pba_date').data('start-date')),
      endDate: new Date()
    });

    if($('#applicant_account_status').val() == 'paused'){
      $("#applicant_payment_date").prop('disabled', true)
      $("#applicant_next_first_due_date").prop('disabled', true)
    }else{
      $("#applicant_payment_date").prop('disabled', false)
      $("#applicant_next_first_due_date").prop('disabled', false)
    }

    if($('#applicant_account_status').val() == 'paused'){
      $('.edit_first_payment_date').hide()
    }else{
      $('.edit_first_payment_date').show()
    }

    $('#applicant_account_status').change(function(){
      if($(this).val() == 'paused'){
        $('.pause_up_to').show()   
        $("#applicant_payment_date").prop('disabled', true)
        $("#applicant_next_first_due_date").prop('disabled', true)
        $('.edit_first_payment_date').hide()
        $('.spi_delete_btn').show()
        $('.check_bounced').show()
      }else if($(this).val() == 'terminate'){
        $("#applicant_payment_date").prop('disabled', true)
        $("#applicant_next_first_due_date").prop('disabled', true)
        $('.edit_first_payment_date').hide()
        $('.pause_up_to').hide()   
      }else{
        $('.pause_up_to').hide()
        $("#applicant_payment_date").prop('disabled', false)
        $("#applicant_next_first_due_date").prop('disabled', false)
        $('.edit_first_payment_date').show()
        $('.check_bounced').hide()
        var date = $('#next_payment').data('date')
        if(date != undefined){
          if(new Date(date) > new Date())
          {
            $('.spi_delete_btn').show()
            $('.check_bounced').show()

          }else{
            $('.spi_delete_btn').hide()
            $('.check_bounced').hide()
          }
        }else{
          $('.spi_delete_btn').hide()
          $('.check_bounced').hide()
        }
      }
    })

    // $("#applicant_next_first_due_date").datepicker( {
    //     placeholder: 'All',
    //     format: "MM yyyy",
    //     minViewMode: "months",
    //     maxViewMode: 'years',
    //     startDate:  new Date(),
    //     autoclose: true
    // });

    $('#applicant_pause_up_to').datepicker({
      format: "mm/dd/yyyy",
      startDate:  new Date(),
      autoclose: true
    })

    $('#applicant_next_first_payment_at').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      endDate: new Date
    })

    $('.spi_dropdowns  #applicant_id').change(function(){
      $('.txn_save_btn').click()
    })

    $('.ach_date').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      endDate: new Date()
    })

    if($('.nested-fields').length == 0) {
      clickAddFields()
    }
    if($('.nested-fields').length >= 100){
      $('.add-item').hide();
    }
    else{
      $('.add-item').show();
    }

    $('.paidDate').change(function(){
      if($(this).val() != ''){
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });

    $(".add-penalty-button").click(function(){
      setTimeout(function(){
        $(".payment_tx_spi_entry [id$='payment_made']:last").removeClass('payment_made')
        $(".payment_tx_spi_entry [id$='payment_type']:last").val("penalty");
        $(".payment_tx_spi_entry [id$='notes']:last").empty().append('<option value="">Please Select</option> <option value="late_fee">Late Fee</option><option value="returned_check">Returned Check</option>');
        $(".payment_tx_spi_entry [id$='payment_type']:last").parent().parent().parent().find('.ff').addClass('bg-penalty');
      }, 200)
    });

    $('#applicant_initial_payment_collector').change(function(){
      if(this.value == 'SweetPay'){
        $('.initial_payment_collector_section').show()
      }
      else{
        $('.initial_payment_collector_section').hide()
      }
    })

    // $('.select_member_id_for_applicant').change(function() {
    //   $('.payment_plan_detail_section').css("opacity",'0')
    //   var element  = this
    //   var store_id =  element.value
    //   if($('#member_id').data('triggerAction') != false){
    //     if(store_id.length > 0){
    //       $.ajax({
    //         type: "get",
    //         url: "/admin/spi_entry_modules/"+store_id+"/spi_entry_store_applicant",
    //         data: {},
    //         success: function(res){
    //           $('#applicant_id').find('option').remove()
    //           $("#applicant_id").append($("<option>").val('').text('Select Applicant'))
    //           $("#applicant_id").append(
    //             $.map(res, function(k,v){
    //               return $("<option>").val(k[3]).text(`${k[2]} - ${k[0] + " " + k[1]}`);
    //             })
    //           );
    //           $('#applicant_id').select2({
    //           });
    //           $('#applicant_id').removeClass('error')
    //           $('#applicant_id').siblings('.error').remove()
    //         }
    //       });
    //     }
    //   }else{
    //     $('#member_id').attr('triggerAction', true)
    //   }
    // })
  }

  firstPaymentFormSubmit(){
   if($('#next_first_payment_date_form').valid()){
      $('.modal').hide()
      $('.modal-backdrop').remove()
    }
  }

  submitForm(){
    if($('#spi_entry_form').valid())
      $('#loading_data').show()
  }

  settwoDecimalPoint(event){
    var element = event.currentTarget
    var value = element.value

    var num = parseFloat(value).toFixed(2)

    if (String(num) == 'NaN'){
      num = 0.00
    }
    $(element).val(num);
  }

  refreshPage(){
    var select = $('#applicant_id')[0]
    select.value = $('#applicant_id').val()
    select.dispatchEvent(new Event("change"))
  }

  editNextPyament(){
    var day = $('#applicant_payment_date').val();
    var order;
    if(day == '1st'){
      order = '16th'
    }else{
      order = '1st'
    }

    $('#applicant_next_first_due_date').val('')
    $("#applicant_payment_date").val(order)
    $("#applicant_payment_date").prev().text(order)
    $('.edit_first_payment_date').hide()
    $('.spi_delete_btn').show()
    $('.check_bounced').show()
    showNextPyamentDate(order)
  }

  enableField(event){
    var element  = event.currentTarget
    console.log($(element).parent().parent().find('input').data('e-signed'))
    var e_signed = $(element).parent().parent().find('input').data('e-signed')
    var funded = $(element).parent().parent().find('input').data('funded')
    var payment_type = $(element).parent().parent().find('input').data('payment-type')
    if(e_signed == true || funded == true && payment_type == 'initial'){
      $(element).parent().parent().find('select.disabledStatus').hide();
      $(element).parent().parent().find('select.enableStatus').show();
      $(element).parent().parent().find('input.disablePaidDate').hide();
      $(element).parent().parent().find('input.enablePaidDate').show();
      $(element).parent().parent().find('input.enablePaidDate').prop('readOnly', false)
    }
    else{
      $(element).parent().parent().find('input').attr('readonly', false)
      $(element).parent().parent().find('textarea').attr('readonly', false)
      $(element).parent().parent().find('select').attr('readonly', false)
      $(element).parent().parent().find('input.disablePaidDate').hide();
      $(element).parent().parent().find('input.enablePaidDate').show();
      $(element).parent().parent().find('select.disabledStatus').hide();
      $(element).parent().parent().find('select.enableStatus').show();
      $(element).parent().parent().find('select.disabledPaymentNotes_emi_transition').hide();
      $(element).parent().parent().find('select.enablePaymentNotes_emi_transition').show();
      $(element).parent().parent().find('select.disabledPaymentNotes_penalty').hide();
      $(element).parent().parent().find('select.enablePaymentNotes_penalty').show();
      $(element).remove()
    }
    $(".paidDate").each(function(index, item) {
      $(item).datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        endDate: new Date(),
        startDate: new Date($(item).data('end-date'))
      });
    })
     $(".payment_due_date").each(function(index, item) {
      $(item).datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        endDate: new Date(),
        startDate: new Date($(item).data('end-date'))
      });
    })
    pymtNotesValidation()
  }

  // PaymentStatusChanges(event){
  //   var element  = event.currentTarget
  //   var emi = $('#payment_history_section').data('payment-amount')
  //   var enterd_amount = parseFloat($(element).parent().parent().find('.payment_made').val())
  //   var due_amount    = parseFloat($(element).parent().parent().find('.due_amount').text())
  //   var paid_date     = $(element).parent().parent().find('.paidDate').val()

  //   var check_amount_sign =  Math.sign(emi - enterd_amount)
  //   var clicked_element   = $(element).parent().parent().parent()
  //   var $rowToCopy        = clicked_element // Find the closest row
  //   var $copiedRow        = $rowToCopy.clone(); // Clone the row
  //   var rowCounter        = $('.nested-fields').length
  //   if($(element).val() == 'bounced'){
  //     if(enterd_amount != NaN && paid_date != ''){
  //       $rowToCopy.after($copiedRow);
  //       $copiedRow.find('input, select').each(function() {
  //         var originalID = $(this).attr('id')
  //         var name       = $(this).attr('name')
  //         var originalIdNumber;
  //         if(originalID != undefined){
  //           originalIdNumber = originalID.match(/\d+/g)[0];
  //           var newId        = originalID.replace(originalIdNumber, rowCounter);
  //           var newName      = name.replace(originalIdNumber, rowCounter)
  //           $(this).attr('id', newId);
  //           $(this).attr('name', newName);
  //         }
  //       })
  //       $($copiedRow).find('[id$=status]').empty().append('<option value="initiated"></option>');
  //       $(element).parent().parent().find("input[type='text']").css("color", "red");
  //       $(element).parent().parent().find("input[type='number']").css("color", "red");
  //       $(element).parent().parent().find("select").css("color", "red");

  //       var options = [
  //         { value: "bounced", text: 'Bounced' },
  //         { value: "in_process", text: "In Process" },
  //         { value: "cleared", text: "Cleared" },
  //         { value: "not_received", text: "Not Received" },
  //         { value: "unable_to_process", text: "Unable to Process" }
  //         // Add more options as needed
  //       ];
  //       $(element).parent().parent().find('select.enableStatus').empty()
  //       $.each(options, function(index, option) {
  //         $(element).parent().parent().find('select.enableStatus').append($('<option>', {
  //           value: option.value,
  //           text: option.text
  //         }));
  //       });
  //       // $(element).parent().parent().find('input').prop('readonly', true)
  //       // $(element).parent().parent().addClass('inp-disable') //.find('input').prop('readonly', true)
  //       // $(element).parent().parent().find('select').addClass('disabled_field')
  //       // $(element).parent().parent().find('input').addClass('disabled_field')
  //       // $(element).parent().parent().find('input').attr('readonly', true)
  //     }
  //   }
  //   else if($(element).val() == 'cleared' && enterd_amount != due_amount && !(enterd_amount > due_amount) && check_amount_sign == 1){
  //     $rowToCopy.after($copiedRow);
  //     $copiedRow.find('input, select').each(function() {
  //       var originalID = $(this).attr('id')
  //       var name       = $(this).attr('name')
  //       var originalIdNumber;
  //       if(originalID != undefined){
  //         originalIdNumber = originalID.match(/\d+/g)[0];
  //         var newId        = originalID.replace(originalIdNumber, rowCounter);
  //         var newName      = name.replace(originalIdNumber, rowCounter)
  //         $(this).attr('id', newId);
  //         $(this).attr('name', newName);
  //       }
  //     });
  //     var emi = $('#payment_history_section').data('payment-amount')
  //     $(element).parent().parent().find('select.enableStatus').empty().append('<option value="cleared">Cleared</option>');
  //     var short_pay = $(element).parent().parent().find("input[type='number']").val()
  //     var remaning_amount = parseFloat(emi) - parseFloat(short_pay)
  //     $copiedRow.find('.due_amount').text(remaning_amount)
  //     $copiedRow.find('.payment_made').val(remaning_amount)
  //     $(element).parent().parent().addClass('inp-disable') //.find('input').prop('readonly', true)
  //     $(element).parent().parent().find('select').addClass('disabled_field')
  //     $(element).parent().parent().find('input').addClass('disabled_field')
  //     $(element).parent().parent().find('input').attr('readonly', true)
  //   }else{
  //     $(element).parent().parent().find("input[type='text']").css("color", "#212529");
  //     $(element).parent().parent().find("input[type='number']").css("color", "#212529");
  //     $(element).parent().parent().find("select").css("color", "#212529");
  //   }
  // }

  PaymentMadeCalculate(event){
    var element  = event.currentTarget
    var total_payment_made = 0
    $('.payment_made').each(function(index, item){
      var a = parseFloat(item.value) || 0.0
      total_payment_made += a
    })
    $('#total_amount_paid').text(total_payment_made.toFixed(2))
  }
}

window.DueAmountCalculate = function(){
  var total_due_amount = 0;
  $('.due_amount').each(function(index, item){
    var a = parseFloat(item.value)
    total_due_amount += a
  })
  $('#total_due_amount').text(total_due_amount.toFixed(2))
}

window.showNextPyamentDate = function(order = ''){
  var currentDate = new Date();
  var firstDate = new Date();
  firstDate.setDate(1);
  // var fifteenthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
  // console.log($(this).val())
  if($(this).val() != ''){
    $('.next_payment_at').show()
  }else{
    $('.next_payment_at').hide()
  }
  console.log("Order: 000P " + order)
  // setTimeout(function() {
    console.log("Order: " + order + " " + currentDate)
    var currentDate = new Date();
    var currentDay = currentDate.getDate();
    currentDate.setMonth(currentDate.getMonth() + 1, 1);
    if(order == '16th'){
      $("#applicant_next_first_due_date").datepicker( {
        placeholder: 'All',
        format: "MM yyyy",
        minViewMode: "months",
        maxViewMode: 'years',
        startDate:  currentDate,
        autoclose: true
      });

      console.log("Order: loading.... 16th")
      if (currentDay < 16) {
        $("#applicant_next_first_due_date").datepicker('setStartDate', new Date);
        currentDate.setDate(1);
      }else{
        $("#applicant_next_first_due_date").datepicker('setStartDate', currentDate);
      }
      console.log("Order: " + order + " " + currentDate)
    }else{
      var currentDate = new Date();
      console.log("Order: " + order + " " + currentDate)
      currentDate.setMonth(currentDate.getMonth() + 1, 1);
      $("#applicant_next_first_due_date").datepicker( {
          placeholder: 'All',
          format: "MM yyyy",
          minViewMode: "months",
          maxViewMode: 'years',
          startDate:  currentDate,
          autoclose: true
      });
      console.log("Order: loading.... 1st")
      $("#applicant_next_first_due_date").datepicker('setStartDate', currentDate);
    }
  // }, 1000)
}


window.clickAddFields = function() {
  $('.add_fields').click()
}

window.validator = function(){
  $.validator.addMethod("numeric", function(value, element) {
    return this.optional(element) || /^[0-9]+$/.test(value);
  });
}

window.pymtNotesValidation = function(){
  try {
    $('.note_selection').each(function() {
      $(this).rules('add', {
        required: true,
        messages: {
          required: 'Please select any notes.',
        }
      });
    });
  }
  catch(err) {
     // console.log(err.message);
  }
}


