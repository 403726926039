import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spi-report-module"
export default class extends Controller {
  connect() {
    console.log('spi report')
    $('#columns').select2({
      placeholder: "All",
      allowClear: true
    });
  

    $('#spi_report_form').validate()

    $('#account_status').select2({})

    $('#loading_data').hide()

    var currentDate = new Date();

    // Calculate the start date based on the current date
    if (currentDate.getDate() >= 16) {
        // If current date is 16th or later, start from next month
        var endDate = '+2M' // Last day of the month after two months
    } else {
        // Otherwise, start from the current month
        var endDate = '+1M' // Last day of the month after two months
    }

    if($('.admin_member_id').data('user') == "Member"){
      $('.admin_member_id').select2({
        minimumInputLength: 3,
        placeholder: 'All',
        allowClear: true
      });
      $('.admin_member_id').prop('required', false)
    }else{
      if($('#report_view').val() == 'paused_transactions_with_inactive'){
        $('.admin_member_id').select2({
          minimumInputLength: 3,
          placeholder: 'All',
          allowClear: true
        });
      }else{
        $('.admin_member_id').select2({
          minimumInputLength: 3,
          placeholder: 'Select Member',
          allowClear: true
        });
      }
    }

    $(".month_date").datepicker( {
      format: "MM yyyy",
      endDate: endDate,
      minViewMode: "months",
      maxViewMode: 'years',
      autoclose: true
    });

    // $('#report_view').change(function(e){
    //   // updateMemberSelection(e)
    //   if($(this).val() == 'payment_due_report'){
    //     // $('.admin_member_id').select2('destroy');
    //     // if($('.admin_member_id').data('user') == 'Member'){
    //     //   $('.admin_member_id').select2({
    //     //     placeholder: 'All',
    //     //     allowClear: true
    //     //   });
    //     // }else{
    //     //   $('.admin_member_id').select2({
    //     //     allowClear: true
    //     //   });
    //     // }
    //     $('.admin_member_id').prop('disabled', true);
    //     $('#account_status').parent().parent().parent().hide()
    //   }
    //   else if($(this).val() == 'paused_transactions_with_inactive'){
    //     $('.admin_member_id').select2({
    //       placeholder: 'All',
    //       allowClear: true
    //     });
    //     $('#account_status').parent().parent().parent().show()
    //     $('#account_status').select2({
    //       placeholder: 'All'
    //     });
    //     // $('.month_date').attr('placeholder', 'All');
    //     // $('.month_date').prop('required', false);
    //   }
    //   else{
    //     $('.month_date').removeAttr('placeholder');
    //     // $('.admin_member_id').prop('required', true);
    //     // if($('.admin_member_id').data('user') == 'Member'){
    //     //   $('.admin_member_id').select2({
    //     //     placeholder: 'All',
    //     //     allowClear: true
    //     //   });
    //     // }else{
    //     //   $('.admin_member_id').select2({
    //     //     allowClear: true
    //     //   });
    //     // }
    //     $('.month_date').prop('required', true);
    //     $('#account_status').parent().parent().parent().hide()
    //   }
    // })

    $('#report_view').change(function(){
      if(this.value == 'payment_due_report'){
        $('.month_date').parent().parent().hide()
        $('.month_date_range').show()
        $('.include_servicing_checkbox').show()
        $('#account_status').parent().parent().parent().hide()
      }
      else if($(this).val() == 'paused_transactions_with_inactive'){
        $('.month_date').parent().parent().hide()
        $('#member_selection_id-error').hide()
        $('.month_date').prop('required', false)
        $('.admin_member_id').prop('required', false)
        $('.admin_member_id').select2('destroy');
        $('.admin_member_id').select2({
          placeholder: 'All',
          allowClear: true
        });
        $('#account_status').parent().parent().parent().show()
        $('#account_status').select2({
          placeholder: 'All'
        });
      }
      else{
        if($('.admin_member_id').data('user') == "Member"){
          $('.admin_member_id').select2({
            minimumInputLength: 3,
            placeholder: 'All',
            allowClear: true
          });
          $('.admin_member_id').prop('required', false)
        }else{
          $('.admin_member_id').select2({
            placeholder: 'Select Member',
            allowClear: true
          });
          $('.admin_member_id').prop('required', true)
        }
        $('#member_selection_id-error').hide()
        $('.month_date').parent().parent().show()
        $('.month_date').prop('required', true)
        $('.month_date_range').hide()
        $('#account_status').parent().parent().parent().hide()
        $('.include_servicing_checkbox').hide()
      }
    });

    $('.month_date').change(function(){
      $(this).datepicker("hide");
    })

    $('.right-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '+=100' }, 100);
    })
    $('.left-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '-=100' }, 100);
    })

    $('.admin_member_id').change(function () {
      $('#member_selection_id-error').hide()
    });

    $('#month').change(function () {
      $(this).removeClass('error')
      $('#month-error').hide()
    })
  }

  updateMemberSelection(event){
    var report_view = event.target.value
    var user = $(event.target).data('user')
    var url;
    if (report_view == 'paused_transactions_with_inactive'){
      report_view = 'paused_transactions_with_inactive'
    }else{
      report_view = 'all'
    }

    if(user == 'Member'){
      url = `/member/spi_reports/${report_view}/report_view_filter`      
    }
    else{
      url = `/admin/spi_reports/${report_view}/report_view_filter`      
    }

    $.ajax({
      type: "get",
      url: url,
      data: {},
      contentType: "application/json",
      dataType: "json",
      success: function(res){
        $("#member_selection_id option").remove()
         $('#member_selection_id').append('<option value="">All</option>')
        $.each(res.stores, function(index, item) {
          $("#member_selection_id").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
      }
    });
  }

  submitForm(){
    if($('#spi_performance_report_form').valid())
      $('#loading_data').show()
  }
}
