import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="app-edit-email-phone"
export default class extends Controller {
  connect() {
    console.log('applicant_edit_detail_payment')

    $('.applicant_phone_number').keyup(function(){
      addDashes($(this))
    })
    
    $('#applicant_edit_detail_with_payment').validate({
      rules: {
        'applicant[email]': {
          required: true,
          emailregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[phone]': {
          required: true,
          minlength: 12,
          maxlength: 12,
          phoneregex: true,
        },
        "applicant[co_phone]": {
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true,
        },
        "applicant[co_email]": {
          required: true,
          emailregex: true,
          primarynotallow: true,
          minlength: 2,
          maxlength: 100
        }
      },
      messages: {
        'applicant[email]': {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.'
        },
        'applicant[phone]': {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        },
        "applicant[co_email]": {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          primarynotallow: "Email needs to be different from primary applicant.",
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.',
        },
        "applicant[co_phone]": {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      }
    })

    $.validator.addMethod("phoneregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
    });

    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });

  }
}
