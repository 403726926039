import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-performance-report"
export default class extends Controller {
  connect() {
    console.log('spi_performance_reports')
    $('#loading_data').hide()

    $('#spi_performance_member_id').select2({
      placeholder: 'All',
      minimumInputLength: 3      
    })

    $('#spi_performance_report_form').validate()

    $('#spi_performance_member_id').change(function(){
      $('#spi_performance_member_id-error').hide()
    })

    $('.report_type').change(function(){
      if($(this).val() == 'quarterly'){
        $('.month_time_priod_section').hide()
        $('.year_time_priod_section').show()
        $('.year_from').prop('disabled', false)
        $('.year_to').prop('disabled', false)
        $('.month_from').prop('disabled', true)
        $('.month_to').prop('disabled', true)
      }else{
        $('.year_time_priod_section').hide()
        $('.month_time_priod_section').show()
        $('.year_from').prop('disabled', true)
        $('.year_to').prop('disabled', true)
        $('.month_from').prop('disabled', false)
        $('.month_to').prop('disabled', false)
      }
    })

    const lastYear = new Date().getFullYear() - 1;

    // Initialize month_from datepicker
    $(".month_from").datepicker({
      format: "MM yyyy",
      startDate: new Date(lastYear, 0, 1),
      endDate: new Date(),
      minViewMode: "months",
      maxViewMode: 'years',
      orientation: "bottom",
      autoclose: true,
    }).on('changeDate', function(e) {
      // Update month_to startDate based on month_from selection
      const selectedDate = e.date;
      $(".month_to").datepicker('setStartDate', selectedDate);
    });

    // Initialize month_to datepicker
    $(".month_to").datepicker({
      format: "MM yyyy",
      startDate: new Date(lastYear, 0, 1),
      endDate: new Date(),
      minViewMode: "months",
      maxViewMode: 'years',
      orientation: "bottom",
      autoclose: true
    });
  }

  submitForm(){
    if($('#spi_performance_report_form').valid())
    $('#loading_data').show()
  }
}